import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAccount, useConnect, useSignMessage } from 'wagmi'

import { Button, ButtonProps, useToast } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { CLIENT_MESSAGE_LOGIN } from 'config/constants/messages'
import { createWallets, getDocLink } from 'config/wallet'
import { useAuthServer } from 'contexts/Auth/hooks/useAuthServer'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useActiveHandle } from 'hooks/useEagerConnect.bmp'

const ButtonConnect = styled(Button)`
  height: 35px;
  border-radius: 5px;
  padding: 0px 10px;
  margin: 0px 0px 0px 10px;
`

const TextConnect = styled.div`
  white-space: nowrap;
`

const ConnectWalletAndLoginButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { toastError } = useToast()
  const { login: loginUser, signUp } = useAuthServer()
  const { login } = useAuth()
  const { address } = useAccount()
  const { account } = useActiveWeb3React()
  const { signMessageAsync } = useSignMessage()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()
  const [open, setOpen] = useState(false)

  const docLink = useMemo(() => getDocLink(code), [code])

  const handleLoginUser = useCallback(
    async (_account: string) => {
      if (!_account) return

      signUp({ account: _account }, async (none) => {
        if (!none) {
          toastError(t('Failed to sign in'))

          return
        }

        const msg = CLIENT_MESSAGE_LOGIN.join('\n')
          .replace('{WALLET_ADDRESS}', _account.toLowerCase())
          .replace('{NONCE}', none)
        const signature = await signMessageAsync({ message: msg })
        loginUser({ account: _account, signature })
      })
    },
    [loginUser, signMessageAsync, signUp, toastError, t],
  )

  const handleClick = () => {
    if (account) {
      handleLoginUser(account)
      return
    }

    if (typeof __NEZHA_BRIDGE__ !== 'undefined' && !window.ethereum) {
      handleActive()
      handleLoginUser(address)
    } else {
      setOpen(true)
    }
  }

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  return (
    <>
      <ButtonConnect type="button" onClick={handleClick} {...props} style={{ boxShadow: 'none' }}>
        {children || <TextConnect>Connect Wallet</TextConnect>}
      </ButtonConnect>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        wcm-modal {
          position: relative;
          z-index: 10000;
        }
      `}</style>
      <WalletModalV2
        docText={t('Learn How to Connect')}
        docLink={docLink}
        isOpen={open}
        wallets={wallets}
        login={login}
        onDismiss={() => setOpen(false)}
        onLoginUser={handleLoginUser}
      />
    </>
  )
}

export default ConnectWalletAndLoginButton
